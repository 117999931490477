import PropTypes from 'prop-types';
import cn from 'class-names';
import { useEffect } from 'react';

import { getContent } from '@/utils/helper';
import { analytics, getAnchorNavigationDetail } from '@/utils/analytics';

import CtfCta from '@/molecules/CTA/index';

const MAXIMUM_POSITION_TOP_ACTIVE_PX = 200;
export default function AnchorNavigation({
  anchor: { sys: { id: anchorId } } = {},
  entries,
}) {
  const handleAnchorNavigate = (e) => {
    analytics({
      window,
      document,
      detail: getAnchorNavigationDetail(e),
    });
  };

  const anchorContent = getContent(anchorId, entries);
  const fields = anchorContent?.fields;

  // listen to scroll events and update anchor navigation active status
  useEffect(() => {
    if (!fields?.links?.length) {
      return;
    }

    const handleScroll = () => {
      // add/remove anchor class on scroll
      const anchorNavs = document.querySelectorAll(
        '.aaco-anchor-navigation > div > a'
      );
      const anchorNavsSize = anchorNavs?.length || 0;
      anchorNavs?.forEach((anchor, index) => {
        // 1. get CURRENT anchor position
        const id = anchor?.getAttribute('href');
        const targetElement = document.querySelector(id);
        if (!targetElement) {
          return;
        }
        const targetElementPositon = targetElement?.getBoundingClientRect();

        // 2. get NEXT anchor position
        const nextIndex = index + 1;
        const hasNextIndex = nextIndex < anchorNavsSize;
        let nextTargetElementPositon;
        if (hasNextIndex) {
          const nextAnchor = anchorNavs[nextIndex];
          const nextId = nextAnchor?.getAttribute('href');
          const nextTargetElement = document.querySelector(nextId);

          if (nextTargetElement) {
            nextTargetElementPositon =
              nextTargetElement?.getBoundingClientRect();
          }
        }

        // 3. check if
        ////// - current anchor top is in the active range
        const isInActiveRange =
          targetElementPositon.top < MAXIMUM_POSITION_TOP_ACTIVE_PX;
        ////// - next anchor top is outside the active range
        const isNextAnchorOutsideActiveRange =
          nextTargetElementPositon?.top > MAXIMUM_POSITION_TOP_ACTIVE_PX;
        ////// purpose: only mark the last active anchor as active
        const isLastAnchorInActiveRange =
          isInActiveRange && (!hasNextIndex || isNextAnchorOutsideActiveRange);
        if (isLastAnchorInActiveRange) {
          anchor.classList.add('active');
        } else {
          anchor.classList.remove('active');
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [fields?.links]);

  return (
    <div
      className={cn(
        'aaco-anchor-navigation hidden-print w-full h-[68px] overflow-x-scroll md:overflow-x-auto',
        'flex content-center items-center',
        'px-8 md:px-14 z-[75]'
      )}
    >
      {fields?.links?.length > 0 &&
        fields.links.map((field) => (
          <div key={field?.sys?.id} id="">
            <CtfCta
              entries={entries}
              field={field}
              noWrap
              hideIcon={true}
              onClick={handleAnchorNavigate}
              className="mr-[30px] text-acacia"
            />
          </div>
        ))}
    </div>
  );
}

AnchorNavigation.propTypes = {
  anchor: PropTypes.object,
  entries: PropTypes.array,
};
