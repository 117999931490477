import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { analytics, getScrollToTopDetail } from '@/utils/analytics';
import { isDDEnv, isWHEnv } from '@/utils/helper';

import ButtonIcon from '@/atoms/ButtonIcon/ButtonIcon';

export default function PageContent({ children, anchorNavigation }) {
  const [showButton, setShowButton] = useState(false);
  const [showAnchor, setShowAnchor] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const hasOverflow = window.pageYOffset > 200;
      setShowButton(hasOverflow);

      const windowHeight = window.innerHeight;
      const hasOverflowExceedWindowHeight = window.pageYOffset > windowHeight;
      setShowAnchor(hasOverflowExceedWindowHeight);
    };

    window.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = (e) => {
    analytics({
      window,
      document,
      detail: getScrollToTopDetail(e),
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const isWH = isWHEnv();
  const isDD = isDDEnv();
  return (
    <>
      {children}
      {showAnchor && isWH && anchorNavigation}
      {showButton && (
        <>
          {isDD && anchorNavigation}
          <div className="aaco-back-to-top z-10 fixed bottom-16 w-fit right-0">
            <ButtonIcon
              style="bg-acacia h-10 mr-[35px] lg:mr-[31px] hover:bg-white hover:border-warm-grey text-white hover:text-acacia shadow-back-to-top dd-kr:shadow-none dd-en:shadow-none"
              onClick={scrollToTop}
              icon="arrow-up"
              role="button"
              width={6.74}
              height={11.67}
            />
          </div>
        </>
      )}
    </>
  );
}

PageContent.propTypes = {
  children: PropTypes.node,
  anchorNavigation: PropTypes.node,
};
