import { useContext, useEffect, useRef, useState } from 'react';
import ErrorPage from 'next/error';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { DispatchContext } from '@/context/index';

import {
  getAllPages,
  getPage,
  getDynamicPages,
  getTagsByTagGroupId,
  getAllTagGroups,
  getAllEnvironments,
  switchEnvironment,
  getTotalRequests,
  getRequests,
  switchToPreview,
  switchToMain,
  getTagsByIds,
  resetCache,
} from '@/utils/api';
import {
  getContent,
  findContentIndex,
  findOneContentByType,
  isDDEnv,
  isWHEnv,
} from '@/utils/helper';

import AnchorNavigation from '@/molecules/AnchorNavigation/AnchorNavigation';
import BlockRenderer from '@/global/BlockRenderer';
import PageContent from '@/global/PageContent';

import {
  CtfPageSlug,
  CtfTags,
  PaginationDefaults,
  CtfContentQueryOrder,
  ContentQueryOrder,
} from '@/utils/constants';

function Page({
  page,
  assets,
  entries,
  customEnvironment,
  robotsIndex,
  pageTags,
  showVersion,
}) {
  const router = useRouter();
  const dispatch = useContext(DispatchContext);
  const container = useRef(null);
  useEffect(() => {
    if (customEnvironment) {
      dispatch({
        "type": "SET_ENVIRONMENT",
        "payload": customEnvironment,
      });

      switchEnvironment(customEnvironment);
    }
  }, [customEnvironment, dispatch])

  const anchorNavigation = findOneContentByType(
    'anchorNavigation',
    page,
    entries
  );

  const pagePreviewContent =
    page?.fields?.metaDescription ? page?.fields.metaDescription : page?.fields?.previewText &&
    getContent(page?.fields?.previewText?.sys?.id, entries)?.fields?.text
      ?.content?.[0]?.content?.[0]?.value;
  const hostname = process.env.NEXT_PUBLIC_URL ?? '/';
  const canonicalHost = process.env.NEXT_PUBLIC_URL_WWW ?? '/';
  const pageUrl = `${canonicalHost}${router?.asPath}`;
  const navigationColor = page?.fields?.navigationColor ?? 'warmer-grey';
  const pageNoIndexNoFollow = !!page?.fields?.noIndexNoFollow;

  const isDD = isDDEnv();
  const isWH = isWHEnv()

  const showDefaultAnchorNavigation = isDD


  return (
       <div className={`app-container`} ref={container} aria-label={page?.fields.slug}>
        <Head>
          {pagePreviewContent && (
            <meta name="description" content={pagePreviewContent} />
          )}
          {page?.fields.slug === 'our-cuts' && (<>
            <link rel="alternate" href={`${hostname}/us/our-cuts`} hrefLang="en-us" />
            <link rel="alternate" href={`${hostname}/au/our-cuts`} hrefLang="en-au" />
          </>)}
          <link rel="canonical" href={page?.fields?.canonicalTag ?? pageUrl} />
          {(!robotsIndex || pageNoIndexNoFollow) && (
            <meta name="robots" content="none" />
          )}
          <title>{page.fields.title}</title>
        </Head>
        <PageContent
          anchorNavigation={
            showDefaultAnchorNavigation &&
            anchorNavigation && (
              <AnchorNavigation
                anchor={anchorNavigation}
                assets={assets}
                entries={entries}
              />
            )
          }
        >
          <BlockRenderer
            blocks={page.fields.content}
            tags={pageTags}
            slug={page.fields?.slug}
            assets={assets}
            entries={entries}
            pageTitle={page.fields.title}
            showVersion={showVersion}
            anchorNavigation={anchorNavigation}
            navigationColor={navigationColor}
          />
        </PageContent>
      </div>
  );
}

Page.propTypes = {
  page: PropTypes.object,
};

export default Page;

export async function getStaticProps({ preview = false, params, locale }) {
  let page = null;
  let entries = [];
  let assets = [];
  let customEnvironment = null;
  let robotsIndex = true;
  let showVersion = false;

  if (process.env.APP_ENV !== 'production') {
    robotsIndex = false;
    showVersion = true;

    const environments = (await getAllEnvironments())?.data?.items?.map((i) => i.name);
    if (params.page && environments.includes(params.page[0])) {
      switchEnvironment(params.page[0]);
      customEnvironment = params.page[0];
      params.page = params.page.splice(1);
    }
  }

  if (preview) {
    switchToPreview();
  } else {
    switchToMain();
  }

  // TODO: Confirm if we use slug='home' as the homepage identifier.
  const payload = {
    slug: params.page && params.page.length ? params.page[0] : CtfPageSlug.HOME,
    preview,
  };

  // Reset cache because Azure Function might treat this as a long-running process which would prevent static cache
  // from updating properly
  resetCache();
  const resp = await getPage(payload, 10, locale);
  if (resp.status !== 200 || !resp.data || resp.data.items.length === 0) {
    return {
      notFound: true,
      revalidate: true,
    };
  }

  // Set the page data
  page = resp.data?.items?.[0] || {};

  // Supply the entries and assets
  if (resp.data?.includes) {
    entries = resp.data.includes?.Entry ?? [];
    assets = resp.data.includes?.Asset ?? [];
  }

  const pageTags = page.fields?.['tags']?.map((tag) => entries.find((entry) => entry?.sys?.id === tag?.sys?.id)) || null;

  const getEntriesToProcess = (links) => {
    let entryIds = [];
    links.forEach((link) => {
      entryIds.push(link.sys.id);
      const content = getContent(link.sys.id, entries);

      if (!content) {
        return;
      }

      Object.entries(content.fields).forEach(([entry, field]) => {
        // This method is mostly to avoid recursively resolving sub-page data
        if (
          !field ||
          (entry === 'content' && content.sys.contentType.sys.id === 'page' && content.fields.slug !== page.fields.slug)
        ) {
          return;
        }

        if (field.sys && field.sys.id) {
          entryIds = [...getEntriesToProcess([field]), ...entryIds];
        } else if (field[0] && field[0].sys && field[0].sys.id) {
          entryIds = [...getEntriesToProcess(field), ...entryIds];
        }
      });
    });

    return entryIds;
  };

  const contentToProcess = [
    ...(page?.fields?.content || []),
    page?.fields?.previewText,
  ].filter((content) => content);
  const entryIds = contentToProcess
    ? getEntriesToProcess(contentToProcess)
    : [];
  entries = entries.filter(c => entryIds.includes(c.sys.id) || ['tag', 'tagGroup'].includes(c.sys.contentType.sys.id));
  entries.push(page);

  // Do a loop of page's content and check each component for:
  // - if it has queryTags field then fetch the required data and store it in fields.pages
  // - if it has tagGroups field then fetch the tags for each tagGroup
  let newEntries = [];
  let newAssets = [];
  entries = await (entries ? Promise.all(
    entries.map(async (content) => {
      // Strip away unnecessary fields
      content = {
        sys: {
          id: content?.sys?.id,
          contentType: {
            sys: {
              id: content?.sys?.contentType?.sys?.id,
            }
          }
        },
        fields: content.fields,
      };

      if (!entryIds.includes(content.sys.id)) {
        return content;
      }

      if (content.sys.contentType.sys.id === 'navigation') {
        //fetch all tagGroups and it tags for search area
        const resp = await getAllTagGroups(preview);

        //construct array of object of tagGroup with tags
        let tagGroupsWithTags = null;
        if (resp.data.items) {
          tagGroupsWithTags = await Promise.all(
            Object.values(resp.data.items).map(async (j) => {
              const tags = await getTagsByTagGroupId(j.sys.id);
              return {
                id: j.sys.id,
                name: j.fields.name ?? null,
                slug: j.fields.slug || null,
                tags:
                  tags?.data?.items && Object.keys(tags?.data?.items)?.length > 0
                    ? Object.values(tags.data.items)?.map((k) => {
                        return {
                          id: k.sys.id,
                          name: k.fields.name ?? null,
                          slug: k.fields.slug ?? '',
                        };
                      }) || null
                    : null,
              };
            })
          );
        }

        //store tabGroupsWithTags data in fields.tagGroupsWithTags
        content = {
          ...content,
          fields: {
            ...content.fields,
            tagGroupsWithTags: tagGroupsWithTags || null,
          },
        };
      }

      if (content.fields.queryTags) {
        //handle default tags/filters
        const queryTagIds = content.fields.queryTags.map((j) => j.sys.id);
        //handle pagination
        let paginationNo = 1;
        let paginationLimit = PaginationDefaults.LIMIT;
        if (params.page && params.page[1] && !isNaN(params.page[1])) {
          paginationNo = params.page[1];
        }

        if (content.fields.queryTagsLimit) {
          paginationLimit = content.fields.queryTagsLimit;
        } else if (content.sys.contentType.sys.id === 'navigationLink') {
          paginationLimit = 1;
        } else if (content.sys.contentType.sys.id === 'cut') {
          paginationLimit = 3;
        }

        // Special case for Content Grid to have custom cards injected into them
        let offset = content.fields.queryTagsOffset ?? (paginationNo - 1) * paginationLimit;
        if (
          content.sys.contentType.sys.id === 'contentGrid' &&
          content.fields.cards
        ) {
          if (paginationNo <= content.fields.cards.length) {
            paginationLimit = paginationLimit - 1;
          } else {
            offset =
              content.fields.cards.length * (paginationLimit - 1) +
              (paginationNo - content.fields.cards.length - 1) *
                paginationLimit +
              1;
          }
        }

        // Fetch Pages data & append new assets & entries
        const dynamicPages = await getDynamicPages(
          null,
          queryTagIds,
          paginationNo,
          paginationLimit,
          [...assets, ...newAssets],
          [...entries, ...newEntries],
          locale,
          offset,
          page.sys.id,
          ['cut', 'contentGrid'].includes(content.sys.contentType.sys.id) ? 'in' : 'all',
          CtfContentQueryOrder.CREATE_DATE === content.fields.contentQueryOrder
            ? ContentQueryOrder.CREATE_DATE
            : ContentQueryOrder.PUBLISH_DATE,
          preview
        );
        newAssets = [...newAssets, ...dynamicPages.filteredNewAssets];
        // Quick optimisation, only keep dynamic assets and taxonomy from these pages
        // Added blockText to the filter list since carousel component needs the entry data
        newEntries = [...newEntries, ...dynamicPages.filteredNewEntries.filter((p) => ['dynamicAsset', 'tag', 'tagGroup', 'blockText'].includes(p.sys.contentType.sys.id))];
        // Replace current Entry data with Entry with appended pages data
        // So that later it can be called using fields.pages
        // Also store default tag/filter in fields.queryTagIds
        content = {
          ...content,
          fields: {
            ...content.fields,
            pages: dynamicPages.resp?.data.items ?? [],
            totalPages: dynamicPages.resp?.data.total ?? 0,
            queryTagIds,
          },
        };
      }

      if (content.fields.tagGroups) {
        //get tagGroupIds
        const tagGroupIds = content.fields.tagGroups.map((j) => j.sys.id);

        //construct array of object of tagGroup with tags
        const tagGroupsWithTags =
          tagGroupIds?.length > 0
            ? await Promise.all(
                tagGroupIds.map(async (j) => {
                  const tagGroup = getContent(j, entries);
                  const tags = await getTagsByTagGroupId(j);
                  return {
                    id: tagGroup.sys.id,
                    name: tagGroup.fields.name ?? null,
                    slug: tagGroup.fields.slug,
                    tags:
                      tags?.data?.items?.length > 0
                        ? tags.data.items
                            ?.map((k) => {
                              return {
                                id: k.sys.id,
                                name: k.fields.name ?? null,
                                slug: k.fields.slug ?? '',
                              };
                            })
                            ?.filter((k) => k.name) || null
                        : null,
                  };
                })
              )
            : null;

        //store tabGroupsWithTags data in fields.tagGroupsWithTags
        content = {
          ...content,
          fields: {
            ...content.fields,
            tagGroupsWithTags,
          },
        };
      }

      return content;
    })
  )
  : null);

  const existingEntryIds = entries.map((j) => j.sys.id);
  newEntries.forEach((j) => !existingEntryIds.includes(j.sys.id) ? entries.push(j) : undefined);

  const existingAssetIds = assets.map((j) => j.sys.id);
  newAssets.forEach((j) =>
    !existingAssetIds.includes(j.sys.id) ? assets.push(j) : undefined
  );

  const filteredAssets = [];
  entries.forEach(e => {
    Object.values(e.fields).forEach(v => {
      if (v && v.sys && v.sys.linkType && v.sys.linkType === 'Asset') {
        filteredAssets.push(v.sys.id);
      }
    });
  });

  assets = assets.filter(a => filteredAssets.includes(a.sys.id));

  const ret = {
    props: { preview, assets, entries, page, customEnvironment, robotsIndex, showVersion, pageTags },
    revalidate: 300,
  };

  if (
    process.env.APP_ENV === 'development' ||
    process.env.APP_ENV === 'staging'
  ) {
    ret.revalidate = 10;
  }

  if (
    resp.data.items.length === 0 ||
    preview ||
    (params.page && params.page[1])
  ) {
    ret.revalidate = 0;
  }

  return ret;
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}
